import cl from 'classnames';
import * as React from 'react';
import styled from 'styled-components';
import { space, SpaceProps, TextColorProps } from 'styled-system';
import { colorsV2 } from '../../../colors-v2';
import { DOLThemeType } from '../../../Common/theme';
import { Separator } from '../../../Layout/Landing/Content/Separator';
import { ResponsiveTypography } from '../../../Typography/v3/ResponsiveTypography';

export interface MetadataModel {
  id: string;
  text?: React.ReactNode;
  icon?: JSX.Element;
  textColor?: TextColorProps<DOLThemeType>['color'];
}

export interface MetaProps extends Omit<TextColorProps<DOLThemeType>, 'color'>, SpaceProps {
  className?: string;
  metadata?: MetadataModel[];
  color?: string;
}

const Main = styled.div`
  ${space};
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .meta-text {
    display: flex;
    align-items: center;
    white-space: nowrap;
    color: ${colorsV2.gray100};
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }

  && {
    margin: 0 -4px;

    > * {
      margin: 0 4px;
    }
  }
`;

/**
 * Rollback to basic Meta component.
 * This is a common ui please extend this component if you can
 * or create another one if too specific
 */
export const Meta = (props: MetaProps) => {
  const { className, metadata, ...rest } = props;
  return (
    <Main className={cl(className, 'shared-meta')} {...rest}>
      {metadata
        ?.filter((metaItem) => Boolean(metaItem?.text))
        .map(({ id, text, icon, textColor }, index, arr) => (
          <React.Fragment key={id}>
            <div className="meta-item">
              <ResponsiveTypography.Text className="meta-text" key={id} color={textColor as any}>
                {icon}
                {text}
              </ResponsiveTypography.Text>
            </div>
            {index < arr.length - 1 ? (
              <Separator bg="black20" width="3px" height="3px" variant="dot" />
            ) : null}
          </React.Fragment>
        ))}
    </Main>
  );
};

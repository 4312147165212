import { fromScreen } from '@doltech/core/lib/responsive/responsive.util';
import * as React from 'react';
import styled from 'styled-components';

import { DescriptionEditorViewProps } from '../../../DescriptionEditor/View/DescriptionEditorView.types';
import { DescriptionEditorView } from '../../../DescriptionEditor/View/DescriptionEditorView';

interface DescriptionViewProps extends Pick<DescriptionEditorViewProps, 'title' | 'mediaInfos'> {
  description?: string;
}

const Main = styled.div`
  margin-bottom: 24px;
  max-width: calc(100vw - 32px);
  ${fromScreen(712)} {
    max-width: 100%;
  }
`;

const compactConfig = {
  mode: 'ellipsis',
  rows: 2,
} as any;

export const DescriptionView = (props: DescriptionViewProps) => {
  const { title, mediaInfos, description } = props;

  return (
    <Main className="description-view">
      <DescriptionEditorView
        title={title}
        body={description}
        mediaInfos={mediaInfos}
        compactConfig={compactConfig}
        compact
      />
    </Main>
  );
};

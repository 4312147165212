import { LinkSettingEnum } from '@doltech/utils/lib/constants';
import { TitleProps as AntTitleProps } from 'antd/lib/typography/Title';
import cl from 'classnames';
import * as React from 'react';

import { Link } from '../../../../../figma/Link';
import { ResponsiveTypography } from '../../../../../figma/Typography/v3/ResponsiveTypography';
import classes from './TitleView.module.less';

export interface TitleViewProps extends Pick<React.HTMLAttributes<HTMLDivElement>, 'style'> {
  title?: string;
  titleLink?: {
    link?: string;
    linkSettings: string[];
  };
  headingLevel?: AntTitleProps['level'] | boolean;
  titleTypoVariant?: string | string[];
}

export const TitleView = (props: TitleViewProps) => {
  const {
    title,
    titleLink,
    headingLevel = 2,
    titleTypoVariant = ['bold/24', 'bold/24', 'bold/32'],
    ...rest
  } = props;

  const titleElement = React.useMemo(() => {
    if (headingLevel) {
      return (
        <ResponsiveTypography.Title
          className={classes.titleView}
          color="black100"
          level={headingLevel as AntTitleProps['level']}
          breakpoints={[0, 458, 776]}
          variant={titleTypoVariant}
          {...rest}
        >
          {title}
        </ResponsiveTypography.Title>
      );
    }

    return (
      <ResponsiveTypography.Paragraph
        color="black100"
        className={classes.titleView}
        breakpoints={[0, 458, 776]}
        variant={['bold/24', 'bold/24', 'bold/32']}
        {...rest}
      >
        {title}
      </ResponsiveTypography.Paragraph>
    );
  }, [headingLevel, rest, title, titleTypoVariant]);

  if (titleLink?.link) {
    const isNofollow = titleLink?.linkSettings?.includes(LinkSettingEnum.NO_FOLLOW);
    const isNewTab = titleLink?.linkSettings?.includes(LinkSettingEnum.OPEN_NEW_TAB);
    return (
      <Link
        className={cl(classes.titleView, classes.link)}
        href={titleLink?.link}
        rel={isNofollow ? 'nofollow' : ''}
        target={isNewTab ? '_blank' : '_self'}
      >
        {titleElement}
      </Link>
    );
  }

  return titleElement;
};

import { IconChevronChevronRight } from '@doltech/icons/IconChevronChevronRight';
import { imgProxyLoader } from '@doltech/utils/lib/image-loader';
import { getFullUrl } from '@doltech/utils/lib/url';
import cl from 'classnames';
import Image from 'next/image';
import * as React from 'react';
import styled from 'styled-components';
import { compose, space, SpaceProps } from 'styled-system';

import no_image from '../../../../images/No_image_available.png';
import {
  ColumnGap,
  RowGap,
} from '../../../../shared/components/PageSectionStructure/PageSectionModel.d';
import { colorsV2 } from '../../../colors-v2';
import { Box } from '../../../Common/BoxV2';
import { Link } from '../../../Link';
import { Meta } from '../components/Meta';
import { ResponsiveTypography } from '../../../Typography/v3/ResponsiveTypography';
import { useFromScreens } from '../../../../hooks/useDeviceQuery.hook';

const HighLight = styled.span`
  white-space: normal;

  em {
    background: ${colorsV2.primary20};
    font-style: normal;
  }
`;

const Main = styled.div`
  height: 100%;

  .image-wrapper {
    overflow: hidden;
    position: relative;
    border-radius: 8px;
  }

  .row-link {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    color: ${colorsV2.blue100};
  }

  .text-link {
    color: ${colorsV2.black100};

    &:hover {
      text-decoration: underline;
    }
  }
`;

const SUPPORT_RENDER_TYPE = {
  TITLE: 'title',
  LINK: 'link',
  DESCRIPTION: 'description',
  DESCRIPTION_LISTING_HORIZONTAL: 'description-listing-horizontal',
  IMAGE: 'image',
  META: 'meta',

  CUSTOM: 'custom',
};

interface RenderItem {
  type?: keyof typeof SUPPORT_RENDER_TYPE;
  dataIndex?: string;
  render?: () => JSX.Element;
  defaultProps?: any;
  wrapperClassName?: string;
}

interface GridTemplate {
  areas?: string;
  rows?: string;
  columns?: string;

  gap?: [ColumnGap, RowGap];
}

export interface StructuralItemLayout extends RenderItem {
  direction?: 'horizontal' | 'vertical';
  gridTemplate?: GridTemplate;
  spacing?: SpaceProps;

  items?: StructuralItemLayout[];
}

interface StructuralCardProps {
  items: StructuralItemLayout[];
  className?: string;
  overrideDescriptionEllipsis?: any;
}

interface GetSupportRenderFromTypeParams {
  renderType: keyof typeof SUPPORT_RENDER_TYPE;
  renderItem: RenderItem;
  descriptionEllipsis?: any;
}

const rowEllipsis = {
  rows: 2,
  tooltip: true,
};

const getSupportRenderFromType = ({
  renderType,
  renderItem,
  descriptionEllipsis,
}: GetSupportRenderFromTypeParams) => {
  switch (renderType) {
    case 'TITLE': {
      const {
        url,
        children,
        snippet,
        headingLevel = 3,
        prefetch = false,
        ...rest
      } = renderItem.defaultProps;
      return (
        <Link prefetch={prefetch} href={getFullUrl(url)} className="text-link">
          {headingLevel ? (
            <ResponsiveTypography.Title
              level={headingLevel}
              ellipsis={rowEllipsis}
              breakpoints={[0, 768]}
              variant={['semi-bold/16-24', 'semi-bold/20-28']}
              {...rest}
            >
              {snippet ? <HighLight dangerouslySetInnerHTML={{ __html: snippet }} /> : children}
            </ResponsiveTypography.Title>
          ) : (
            <ResponsiveTypography.Paragraph
              ellipsis={rowEllipsis}
              breakpoints={[0, 768]}
              variant={['semi-bold/16-24', 'semi-bold/20-28']}
              {...rest}
            >
              {snippet ? <HighLight dangerouslySetInnerHTML={{ __html: snippet }} /> : children}
            </ResponsiveTypography.Paragraph>
          )}
        </Link>
      );
    }
    case 'DESCRIPTION': {
      const { children, snippet, ...rest } = renderItem.defaultProps;
      return (
        <ResponsiveTypography.Paragraph
          color="black80"
          breakpoints={[0, 776]}
          variant={['regular/14-20', 'regular/16-24']}
          ellipsis={descriptionEllipsis}
          {...rest}
        >
          {snippet ? <HighLight dangerouslySetInnerHTML={{ __html: snippet }} /> : children}
        </ResponsiveTypography.Paragraph>
      );
    }

    case 'DESCRIPTION_LISTING_HORIZONTAL': {
      const { children, snippet, ...rest } = renderItem.defaultProps;
      return (
        <ResponsiveTypography.Paragraph
          color="black80"
          variant="regular/14-20"
          ellipsis={descriptionEllipsis}
          {...rest}
        >
          {snippet ? <HighLight dangerouslySetInnerHTML={{ __html: snippet }} /> : children}
        </ResponsiveTypography.Paragraph>
      );
    }
    case 'LINK': {
      const { target, prefetch = false, url, withArrowRight, ...rest } = renderItem.defaultProps;
      return (
        <ResponsiveTypography.Paragraph
          breakpoints={[0, 776]}
          variant={['semi-bold/14-20', 'semi-bold/16-24']}
          color="blue100"
        >
          <Link target={target} prefetch={prefetch} href={getFullUrl(url)} className="row-link">
            <ResponsiveTypography.Text
              color="blue100"
              breakpoints={[0, 776]}
              variant={['semi-bold/14-20', 'semi-bold/16-24']}
              {...rest}
            />
            {withArrowRight && (
              <div className="icon">
                <IconChevronChevronRight />
              </div>
            )}
          </Link>
        </ResponsiveTypography.Paragraph>
      );
    }
    case 'IMAGE': {
      const {
        prefetch = false,
        url,
        alt,
        imageUrl,
        ratio,
        fallback,
        isLoading,
        ...rest
      } = renderItem.defaultProps;
      if (ratio) {
        return (
          <Link prefetch={prefetch} href={getFullUrl(url)} className="image-link">
            <Box className="image-wrapper" {...rest}>
              <Image
                width={1}
                height={1 / ratio}
                alt={alt}
                src={imageUrl || fallback || no_image}
                quality={30}
                objectFit="cover"
                layout="responsive"
                loading="lazy"
                loader={imgProxyLoader}
              />
            </Box>
          </Link>
        );
      }
      return (
        <Link prefetch={prefetch} href={getFullUrl(url)} className="image-link">
          <Box className="image-wrapper" {...rest}>
            <Image
              objectFit="cover"
              layout="fill"
              loader={imgProxyLoader}
              loading="lazy"
              alt={alt}
              src={imageUrl || fallback || no_image}
            />
          </Box>
        </Link>
      );
    }
    case 'META': {
      return <Meta {...renderItem.defaultProps} />;
    }
    case 'CUSTOM': {
      throw new Error('Oops, "render" props not found when using type "CUSTOM" ');
    }
    default:
  }
  return null;
};

interface ItemMainProps extends SpaceProps {
  gridTemplate: GridTemplate;
  dataIndex: string;
}

/* stylelint-disable declaration-block-no-redundant-longhand-properties */
/* stylelint-disable named-grid-areas-no-invalid */
const ItemMain = styled.div<ItemMainProps>`
  width: 100%;
  height: 100%;
  display: ${(p) => (p?.gridTemplate ? 'grid' : 'block')};
  grid-template-areas: ${(p) => p?.gridTemplate?.areas || 'none'};
  grid-template-rows: ${(p) => p?.gridTemplate?.rows || 'none'};
  grid-template-columns: ${(p) => p?.gridTemplate?.columns || 'none'};
  grid-auto-rows: max-content;

  grid-area: ${(p) => p.dataIndex || 'auto'};
  column-gap: ${(p) => p?.gridTemplate?.gap?.[0] || 0}px;
  row-gap: ${(p) => p?.gridTemplate?.gap?.[1] || 0}px;

  ${compose(space)}
`;

export const StructuralCard = (props: StructuralCardProps) => {
  const { className, items, overrideDescriptionEllipsis } = props;
  const [, from776] = useFromScreens([0, 776]);
  const descriptionEllipsis = React.useMemo(() => {
    if (overrideDescriptionEllipsis) return overrideDescriptionEllipsis;
    if (from776) {
      return { rows: 3 };
    }
    return { rows: 2 };
  }, [from776, overrideDescriptionEllipsis]);
  const renderItem = React.useCallback(
    (item: StructuralItemLayout) => {
      if (item?.items?.length > 0) {
        return (
          <ItemMain
            dataIndex={item.dataIndex}
            gridTemplate={item?.gridTemplate}
            className={item?.wrapperClassName || ''}
            key={item.dataIndex}
            {...item.spacing}
          >
            {item.items.filter(Boolean).map(renderItem)}
          </ItemMain>
        );
      }
      return (
        <ItemMain
          dataIndex={item.dataIndex}
          gridTemplate={item?.gridTemplate}
          className={item?.wrapperClassName || ''}
          key={item.dataIndex}
          {...item.spacing}
        >
          {item.render
            ? item.render()
            : getSupportRenderFromType({
                renderType: item.type,
                renderItem: item,
                descriptionEllipsis,
              })}
        </ItemMain>
      );
    },
    [descriptionEllipsis]
  );

  return (
    <Main className={cl('structural-card', className)}>
      {items.filter(Boolean).map(renderItem)}
    </Main>
  );
};
